html {
  scroll-behavior: smooth;
}

.showcase {
  position: relative;
  padding: 48px 32px;

  @include breakpoint(xs) {
    padding: 0 0 16px;
  }
}

:target:before {
  content: "";
  display: block;
  height: 150px;
  margin: -150px 0 0;

  @include breakpoint(xs) {
    height: 94px;
    margin: -94px 0 0;
  }
}

.showcase__category {
  margin-bottom: 72px;

  @include breakpoint(xs) {
    margin-bottom: 48px;
  }

  &--black {
    padding: 32px 0;
    color: white;
    background-color: black;
    border-radius: 12px;

    @include breakpoint(xs) {
      border-radius: 0;
    }
  }
}

.showcase__category-title {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;

  @include breakpoint(xs) {
    font-size: 24px;
    margin-bottom: 16px;
  }
}

.showcase__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include breakpoint(xs) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    padding: 0 20px 0 20px;
  }

  &:last-child {
    margin: 0;
  }
}

.dish {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 300px;
  padding: 8px 24px;
  margin: 0 16px;

  &--disabled {
    pointer-events: none;
  }

  &--disabled .dish__image-soon {
    display: block;
  }

  &--disabled .dish__button {
    background-color: #c6c6c6;
  }

  &:hover .dish__calories {
    display: flex;

    @include breakpoint(xs) {
      display: none;
      margin: 0;
    }
  }

  &--black {
    @include breakpoint(xs) {
      padding: 10px 8px;
      align-items: center;
      margin: 0;
      background-color: transparent !important;
    }
  }

  @include breakpoint(xs) {
    padding: 10px 8px;
    align-items: center;
    margin: 0;
    background-color: #f4f8ff;
    border-radius: 20px;

    &:last-child {
      border: none;
    }
  }
}

.dish--disabled .dish__image::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "СКОРО";
  padding: 4px 8px;
  background-color: #f3a9b0;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border-radius: 6px;
}

.dish__badge--new {
  position: absolute;
  right: 0;
  top: 8px;
  padding: 2px 6px;
  background-color: #f3a9b0;
  color: #fff;
  display: inline-flex;
  font-size: 12px;
  border-radius: 4px;
  font-weight: 600;

  @include breakpoint(xs) {
    left: 0;
    right: auto;
  }
}

.dish__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;

  @include breakpoint(xs) {
    justify-content: space-between;
  }
}

.dish__calories {
  position: absolute;
  padding: 0 8px;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  background-color: $gray-500;
  color: $white;
  border-radius: 12px;
}

.dish__calories-item {
  font-size: 10px;
  margin-right: 8px;

  &:last-child {
    margin: 0;
  }
}

.dish__image {
  position: relative;
  width: 248px;
  height: 248px;
  background-size: contain;
  flex-shrink: 0;
  background-image: url("images/dishes/placeholder.png");
  background-repeat: no-repeat;
  background-position: center center;

  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  @include breakpoint(xs) {
    max-width: 160px;
    width: 100%;
    max-height: 160px;
  }

  &--1 {
    background-image: url("images/dishes/1.png");
  }

  &--2 {
    background-image: url("images/dishes/2.png");
  }

  &--3 {
    background-image: url("images/dishes/3.png");
  }

  &--4 {
    background-image: url("images/dishes/4.png");
  }

  &--5 {
    background-image: url("images/dishes/5.png");
  }

  &--6 {
    background-image: url("images/dishes/6.png");
  }

  &--7 {
    background-image: url("images/dishes/7.png");
  }

  &--8 {
    background-image: url("images/dishes/8.png");
  }

  &--9 {
    background-image: url("images/dishes/9.png");
  }

  &--10 {
    background-image: url("images/dishes/10.png");
  }

  &--11 {
    background-image: url("images/dishes/11.png");
  }

  &--12 {
    background-image: url("images/dishes/12.png");
  }

  &--13 {
    background-image: url("images/dishes/13.png");
  }

  &--14 {
    background-image: url("images/dishes/14.png");
  }

  &--15 {
    background-image: url("images/dishes/15.png");
  }

  &--16 {
    background-image: url("images/dishes/16.png");
  }

  &--17 {
    background-image: url("images/dishes/17.png");
  }

  &--18 {
    background-image: url("images/dishes/18.png");
  }

  &--19 {
    background-image: url("images/dishes/19.png");
  }

  &--20 {
    background-image: url("images/dishes/20.png");
  }

  &--21 {
    background-image: url("images/dishes/21.png");
  }

  &--22 {
    background-image: url("images/dishes/22.png");
  }

  &--23 {
    background-image: url("images/dishes/23.png");
  }

  &--24 {
    background-image: url("images/dishes/24.png");
  }

  &--25 {
    background-image: url("images/dishes/25.png");
  }

  &--26 {
    background-image: url("images/dishes/26.png");
  }

  &--27 {
    background-image: url("images/dishes/27.png");
  }

  &--28 {
    background-image: url("images/dishes/28.png");
  }

  &--29 {
    background-image: url("images/dishes/29.png");
  }

  &--30 {
    background-image: url("images/dishes/30.png");
  }

  &--31 {
    background-image: url("images/dishes/31.png");
  }

  &--32 {
    background-image: url("images/dishes/32.png");
  }

  &--33 {
    background-image: url("images/dishes/33.png");
  }

  &--34 {
    background-image: url("images/dishes/34.png");
  }

  &--35 {
    background-image: url("images/dishes/35.png");
  }

  &--36 {
    background-image: url("images/dishes/36.png");
  }

  &--37 {
    background-image: url("images/dishes/37.png");
  }

  &--38 {
    background-image: url("images/dishes/38.png");
  }

  &--39 {
    background-image: url("images/dishes/39.png");
  }

  &--40 {
    background-image: url("images/dishes/40.png");
  }

  &--41 {
    background-image: url("images/dishes/41.png");
  }

  &--42 {
    background-image: url("images/dishes/42.png");
  }

  &--51 {
    background-image: url("images/dishes/51.png");
  }

  &--52 {
    background-image: url("images/dishes/52.png");
  }

  &--112 {
    background-image: url("images/dishes/112.png");
  }

  &--113 {
    background-image: url("images/dishes/113.png");
  }

  &--111 {
    background-image: url("images/dishes/111.png");
  }

  &--144 {
    background-image: url("images/dishes/144.png");
  }

  &--201 {
    background-image: url("images/dishes/201.png");
  }

  &--box1 {
    background-image: url("images/dishes/box1.png");
  }

  &--box2 {
    background-image: url("images/dishes/box2.png");
  }

  &--box3 {
    background-image: url("images/dishes/box3.png");
  }

  &--301 {
    background-image: url("images/dishes/301.png");
  }

  &--302 {
    background-image: url("images/dishes/302.png");
  }

  &--veg-1 {
    background-image: url("images/dishes/veg-1.png");
  }

  &--veg-2 {
    background-image: url("images/dishes/veg-2.png");
  }

  &--veg-3 {
    background-image: url("images/dishes/veg-3.png");
  }

  &--veg-4 {
    background-image: url("images/dishes/veg-4.png");
  }

  &--veg-5 {
    background-image: url("images/dishes/veg-5.png");
  }

  &--veg-6 {
    background-image: url("images/dishes/veg-6.png");
  }

  &--burger-1 {
    background-image: url("images/dishes/burger-1.png");
  }

  &--burger-2 {
    background-image: url("images/dishes/burger-2.png");
  }

  &--free-1 {
    background-image: url("images/dishes/free-1.png");
  }

  &--free-2 {
    background-image: url("images/dishes/free-2.png");
  }

  &--sushirito_shrimp {
    background-image: url("images/dishes/sushirito-shrimp.png");
  }

  &--crispy {
    background-image: url("images/dishes/crispy.png");
  }

  &--tom_yam_roll {
    background-image: url("images/dishes/tom-yam-roll.png");
  }

  &--cheesecake {
    background-image: url("images/dishes/cheesecake.png");
  } 

  &--chicken_cheezy {
    background-image: url("images/dishes/chicken_cheezy.png");
  }

  &--lemonade {
    background-image: url("images/dishes/lemonade.png");
  }

  &--kuksi {
    background-image: url("images/dishes/kuksi.png");
  }

  &--moti_coconat {
    background-image: url("images/dishes/moti_coconat.png");
  }

  &--moti_raspberry {
    background-image: url("images/dishes/moti_raspberry.png");
  }

  &--moti_lemon {
    background-image: url("images/dishes/moti_lemon.png");
  }

  &--moti_blueberry {
    background-image: url("images/dishes/moti_blueberry.png");
  }

  &--moti_cherry {
    background-image: url("images/dishes/moti_cherry.png");
  }

  &--moti_peach {
    background-image: url("images/dishes/moti_peach.png");
  }

  &--moti_matcha {
    background-image: url("images/dishes/moti_matcha.png");
  }

  &--moti_chocolate {
    background-image: url("images/dishes/moti_chocolate.png");
  }

  &--moti_amaretto {
    background-image: url("images/dishes/moti_amaretto.png");
  }

  &--bao_beef {
    background-image: url("images/dishes/bao_beef.png");
  }

  &--black_triangle {
    background-image: url("images/dishes/black_triangle.png");
  }

  &--black_rice {
    background-image: url("images/dishes/black_rice.png");
  }

  &--wok_vongole {
    background-image: url("images/dishes/wok_vongole.png");
  }

  &--hot_ugor {
    background-image: url("images/dishes/hot_ugor.png");
  }

  &--fila_ugor {
    background-image: url("images/dishes/fila_ugor.png");
  }

  &--roll_jungle {
    background-image: url("images/dishes/roll_jungle.png");
  }

  &--mnogo_ugra {
    background-image: url("images/dishes/mnogo_ugra.png");
  }

  &--phobo {
    background-image: url("images/dishes/phobo.png");
  }

  &--wonton_meat {
    background-image: url("images/dishes/wonton_meat.png");
  }

  &--wonton_shrimp {
    background-image: url("images/dishes/wonton_shrimp.png");
  }

  &--guakamole_crab {
    background-image: url("images/dishes/guakamole_crab.png");
  }

  &--bubble_roll {
    background-image: url("images/dishes/bubble_roll.png");
  }

  &--pomelo_king {
    background-image: url("images/dishes/pomelo_king.png");
  }

  &--hot_squid {
    background-image: url("images/dishes/hot_squid.png");
  }

  &--triangle_crispy {
    background-image: url("images/dishes/triangle_crispy.png");
  }

  &--caramel_mix {
    background-image: url("images/dishes/caramel_mix.png");
  }

  &--snow_ugor {
    background-image: url("images/dishes/snow_ugor.png");
  }

  &--crunch_salmon {
    background-image: url("images/dishes/crunch_salmon.png");
  }

  &--wok_tom {
    background-image: url("images/dishes/wok_tom.png");
  }

  &--wok_chicken {
    background-image: url("images/dishes/wok_chicken.png");
  }

  &--wok_sea {
    background-image: url("images/dishes/wok_sea.png");
  }

  &--rice_chicken {
    background-image: url("images/dishes/rice_chicken.png");
  }

  &--rice_fish {
    background-image: url("images/dishes/rice_fish.png");
  }

  &--rice_green {
    background-image: url("images/dishes/rice_green.png");
  }

  &--rice_sea {
    background-image: url("images/dishes/rice_sea.png");
  }

  &--cheese {
    background-image: url("images/dishes/cheese.png");
  }

  &--marlin_tartar {
    background-image: url("images/dishes/marlin_tartar.png");
  }

  &--pink_marlin {
    background-image: url("images/dishes/pink_marlin.png");
  }

  &--spicy_salmon {
    background-image: url("images/dishes/spicy_salmon.png");
  }

  &--kreker {
    background-image: url("images/dishes/kreker.png");
  }

  &--hot_salmon {
    background-image: url("images/dishes/hot_salmon.png");
  }

  &--spicy_unagi {
    background-image: url("images/dishes/spicy_unagi.png");
  }

  &--godzi {
    background-image: url("images/dishes/godzi.png");
  }
  &--snow_shrimp {
    background-image: url("images/dishes/snow_shrimp.png");
  }

  &--fila_orange {
    background-image: url("images/dishes/fila_orange.png");
  }

  &--truffle {
    background-image: url("images/dishes/truffle.png");
  }
  
  &--filaxl {
    background-image: url("images/dishes/filaxl.png");
  }

  &--bao_pork {
    background-image: url("images/dishes/bao_pig.png");
  }

  &--char_siu {
    background-image: url("images/dishes/wok_pig.png");
  }

  &--salmon_tartar {
    background-image: url("images/dishes/salmon_tartar.png");
  }

  &--tuna_tartar {
    background-image: url("images/dishes/tuna_tartar.png");
  }

  &--tuna_roll {
    background-image: url("images/dishes/tuna_roll.png");
  }

  &--tuna_salmon {
    background-image: url("images/dishes/tuna_salmon.png");
  }

  &--chicken_roll {
    background-image: url("images/dishes/chicken-roll.png");
  }

  &--fila_yudzu {
    background-image: url("images/dishes/fila_yudzu.png");
  }

  &--unagi_edamame {
    background-image: url("images/dishes/unagi_edamame.png");
  }

  &--hot_tuna {
    background-image: url("images/dishes/unagi_hot.png");
  }

  &--ramen_beef {
    background-image: url("images/dishes/ramen_beef.png");
  }
}

.dish__title {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 8px;
  text-align: center;

  &--small {
    font-size: 20px;
  }

  @include breakpoint(xs) {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }
}

.dish__subtitle {
  text-align: center;
  margin-bottom: 24px;
  flex: 1 1;

  @include breakpoint(xs) {
    display: none;
    text-align: left;
  }
}

.dish__bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include breakpoint(xs) {
    justify-content: center;
  }
}

.dish__info {
  margin-right: 16px;

  @include breakpoint(xs) {
    display: none;
  }
}

.dish__weight {
  font-size: 14px;
}

.dish__price {
  font-size: 18px;
  font-weight: 600;

  @include breakpoint(xs) {
    display: none;
  }
}

.dish__button {
  font-size: 16px;
  font-weight: 600;
  padding: 6px 24px;
  background-color: $rose-300;
  color: $white;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #dc5870;
  }

  @include breakpoint(xs) {
    display: none;
    font-size: 14px;
  }
}

.dish__button--mobile {
  display: none;

  @include breakpoint(xs) {
    display: block;
  }
}

.warning {
  position: sticky;
  top: 20px;
  font-size: 12px;
  line-height: 18px;
  max-width: 360px;
  padding: 10px;
  background-color: #f3a9b0;
  border-radius: 8px;
  z-index: 1;

  @include breakpoint(xs) {
    max-width: 100%;
    width: 100%;
    top: 0;
    border-radius: 0;
  }
}
