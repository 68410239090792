@import "../node_modules/sanitize.css/sanitize.css";
@import "./shared/colors";
@import "./shared/typography";
@import "./shared/breakpoints";
@import "./shared/base";

@import "./components/topbar.scss";
@import "./components/header.scss";
@import "./components/categories.scss";
@import "./components/showcase.scss";
@import "./components/footer.scss";
@import "./components/button_switch.scss";
@import "./components/news.scss";
@import "./components/about.scss";
@import "./components/app_popup.scss";
