.news {
  padding: 40px 32px;

  @include breakpoint(xs) {
    padding: 32px 20px;
  }
}

.news__title {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 24px;

  @include breakpoint(xs) {
    font-size: 20px;
  }
}

.news__item {
  width: 944px;
  height: 296px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;

  @include breakpoint(sm) {
    width: 736px;
    height: 230px;
  }

  @include breakpoint(xs) {
    width: 288px;
    height: 90px;
  }
}

.news__item--sale {
  background-image: url("images/news/sale_news.png");
}
