// ---
// Gray shades
// ---
$white: #ffffff;
$black: #262b33;
$black-300: #333333;

$gray-100: #f2f5fa;
$gray-200: #eeeeee;
$gray-300: #f4f4f4;
$gray-400: #D6D4D4;
$gray-500: #5c5c5c;

$green-300: #d6e78c;

$red-300: #e81919;

$rose-300: #ed647d;
$rose-500: #d2596f;

$blue-300: #2196f3;

$blue-500: #002a80;
