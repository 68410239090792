.categories {
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  border-bottom: 1px solid $gray-300;

  @include breakpoint(xs) {
    padding: 6px 0;
  }
}

.categories__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper {
  width: auto !important;
}

.swiper-slide {
  width: auto !important;

  &:first-child {
    margin-left: 20px;
  }

  &:last-child {
    margin-right: 20px !important;
  }
}

.categories__container {
  display: flex;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.08);
  }

  &:last-child {
    margin: 0;
  }
}

.category-image {
  width: 72px;
  height: 72px;
  background-size: contain;
  background-repeat: no-repeat;

  @include breakpoint(xs) {
    width: 56px;
    height: 56px;
  }

  &--black {
    background-image: url("images/categories/black.png");
  }

  &--veg {
    background-image: url("images/categories/veg.png");
  }

  &--home-boxes {
    background-image: url("images/categories/home-boxes.png");
  }

  &--rolls {
    background-image: url("images/categories/rolls.png");
  }

  &--hot-dishes {
    background-image: url("images/categories/hot-dishes.png");
  }

  &--poke {
    background-image: url("images/categories/poke.png");
  }

  &--soups {
    background-image: url("images/categories/soups.png");
  }

  &--bao {
    background-image: url("images/categories/bao.png");
  }

  &--salads {
    background-image: url("images/categories/salads.png");
  }

  &--snacks {
    background-image: url("images/categories/snacks.png");
  }

  &--desserts {
    background-image: url("images/categories/desserts.png");
  }

  &--wok {
    background-image: url("images/categories/wok.png");
  }

  &--gedza {
    background-image: url("images/categories/gedza.png");
  }

  &--salads {
    background-image: url("images/categories/salads.png");
  }
}

.category-title {
  font-size: 14px;
  font-weight: 600;

  @include breakpoint(xs) {
    margin-top: -4px;
  }
}
