.about {
}

.about__header {
  width: 100%;
  background-color: $gray-300;
  padding: 40px 20px;
}

.about__header-container {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-direction: column;
    justify-content: center;
  }
}

.about__header-image {
  width: 640px;
  height: 220px;
  background-image: url("images/about/car.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 64px;

  @include breakpoint(xs) {
    width: 320px;
    height: 110px;
    margin: 0;
  }
}

.about__header-text {
  text-align: left;

  @include breakpoint(sm) {
    text-align: center;
  }
}

.about__header-phone {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
}

.about__header-text {
}

.about__content {
  width: 100%;
  padding: 40px 32px;
}

.about__container {
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;

  & h1 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 24px;
  }

  & h2 {
    font-weight: 600;
    font-size: 18px;
    margin-top: 24px;
  }
}

.about__category {
}

.about__title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 48px;
}

.about__row {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 24px;

  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.about__item {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-width: 340px;
  margin-right: 48px;

  &:last-child {
    margin: 0;
  }

  @include breakpoint(xs) {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 24px;
    align-items: center;
  }
}

.about__item-title {
  font-size: 28px;
  font-weight: 600;
  color: $rose-300;
}

.about__item-subtitile {
  color: $rose-300;
  margin-bottom: 8px;
}

.about__item-highlighted {
  font-size: 18px;
  font-weight: 600;
}

.about__item-text {
  @include breakpoint(xs) {
    text-align: center;
  }
}

.payment-divider {
  width: 100%;
  height: 1px;
  background-color: $gray-300;
  margin: 24px 0 24px;
}

.payment-info__image {
  width: 200px;
  height: 90px;
  background-image: url("images/about/payment_methods.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 16px;
}

.payment-info__title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 16px;
}

.payment-info__text {
  margin-bottom: 32px;
  p {
    margin-bottom: 24px;

    &:last-child {
      margin: 0;
    }
  }
}

.about__terms {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.about__terms-link {
  margin-right: 16px;
  padding: 6px 12px;
  color: $white;
  background-color: $rose-300;
  border-radius: 12px;
  transition: background-color 0.5s ease;

  &:hover {
    background-color: $rose-500;
  }

  @include breakpoint(xs) {
    margin-bottom: 8px;
    margin-right: 0;
  }

  &:last-child {
    margin: 0;
  }
}
