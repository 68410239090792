.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 95%;
  max-width: 375px;
  left: 50%;
  transform: translate(-50%, 500px);
  pointer-events: none;
  background-color: $white;
  border-radius: 12px 12px 0 0;
  z-index: 999;
  box-shadow: 0 0 6px 1px $gray-400;
  padding: 16px;
  transition: transform 0.4s ease;

  &.is-visible {
    pointer-events: all;
    transform: translate(-50%, 0);
  }
}

.popup__buttons {
  display: flex;
  align-items: center;
}

.popup__install-button {
  padding: 6px 14px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  background-color: $rose-300;
  color: $white;
}

.popup__button--ghost {
  padding: 6px 14px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  background-color: $white;
  border: 1px solid $rose-300;
  color: $rose-300;
  margin-right: 16px;
}

.popup__close {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 24px;
  height: 24px;
  background-image: url("images/icons/closeBig.svg");
}

.popup__icon--king-kong {
  width: 100px;
  height: 86px;
  background-image: url("images/icons/kingkong.png");
  background-size: contain;
  margin-bottom: 8px;
}

.popup__icons {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.popup__icon--ios {
  background-image: url("images/icons/appstore.png");
  background-size: contain;
  width: 100px;
  height: 32px;
  margin-right: 16px;
}

.popup__icon--android {
  background-image: url("images/icons/googleplay.png");
  background-size: contain;
  width: 100px;
  height: 32px;
}

.popup__text {
  font-size: 16px;
  margin-bottom: 16px;
}