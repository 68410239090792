/* Font Definitions */
@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url("fonts/Montserrat/Montserrat-Regular.woff2") format("woff2"),
    url("fonts/Montserrat/Montserrat-Regular.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 500;
  font-style: normal;
  src: url("fonts/Montserrat/Montserrat-Medium.woff2") format("woff2"),
    url("fonts/Montserrat/Montserrat-Medium.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src: url("fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2"),
    url("fonts/Montserrat/Montserrat-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  src: url("fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"),
    url("fonts/Montserrat/Montserrat-Bold.woff") format("woff");
}

@font-face {
  font-family: "OpenSans";
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url("fonts/OpenSans/OpenSans-Regular.woff2") format("woff2"),
    url("fonts/OpenSans/OpenSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "OpenSans";
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src: url("fonts/OpenSans/OpenSans-SemiBold.woff2") format("woff2"),
    url("fonts/OpenSans/OpenSans-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  src: url("fonts/OpenSans/OpenSans-Bold.woff2") format("woff2"),
    url("fonts/OpenSans/OpenSans-Bold.woff") format("woff");
}

@font-face {
  font-family: "Adieu";
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  src: url("fonts/Adieu/Adieu-bold.woff2") format("woff2");
}

@font-face {
  font-family: "Styrene";
  font-display: swap;
  font-weight: 500;
  font-style: normal;
  src: url("fonts/Styrene/Styrene-Medium.woff2") format("woff2");
}

/* Font families */
$montserrat: "Montserrat", system-ui, sans-serif;
$opensans: "OpenSans", system-ui, sans-serif;
$adieu: "Adieu", system-ui, sans-serif;
$styrene: "Styrene", system-ui, sans-serif;
