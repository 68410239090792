// Breakpoints
@mixin breakpoint($class) {
  @if $class == xxs {
    @media only screen and (max-width: 480px) {
      @content;
    }
  } @else if $class == xs {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $class == sm {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $class == md {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $class == lg {
    @media (min-width: 1200px) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xxs, xs, sm, md, lg";
  }
}
