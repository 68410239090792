.mobile-switch {
  position: relative;
  display: none;
  height: 64px;
  border-bottom: 1px solid $gray-300;

  @include breakpoint(sm) {
    display: block;
  }
}

.header__buttons {
  position: absolute;
  left: calc(50% - 248px);
  display: flex;
  align-items: center;
  color: $green-300;

  @include breakpoint(sm) {
    top: calc(50% - 19px);
    left: calc(50% - 152px);
  }
}

.header__button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 240px;
  width: 100%;
  padding: 6px 14px;
  border: 1px solid $green-300;
  border-radius: 16px;
  flex-shrink: 0;
  text-align: center;
  margin-right: 16px;

  &:last-child {
    margin: 0;
  }

  @include breakpoint(sm) {
    padding: 4px 12px;
    max-width: 148px;
    margin-right: 8px;
  }

  &--active {
    background-color: $green-300;
    color: $white;
    border: 1px solid $green-300;
  }
}

.header__button-icon {
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 8px;

  &--burger {
    background-image: url("images/burger.png");
  }

  &--asia {
    background-image: url("images/asia.png");
  }
}

.header__button-text {
  font-weight: 500;
  @include breakpoint(sm) {
    font-size: 14px;
    display: none;
  }
}

.header__button-text--mobile {
  display: none;

  @include breakpoint(sm) {
    font-weight: 600;
    display: block;
  }
}
