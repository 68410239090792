.footer {
  width: 100%;
  color: $white;
  background-color: $blue-500;
  padding: 0 32px 75px;

  @include breakpoint(xs) {
    padding: 0 20px 32px;
  }
}

.footer__container {
  max-width: 1120px;
  margin: 0 auto;
}

.footer__main {
  display: flex;
  flex-flow: row nowrap;
  padding: 80px 0 0;
  align-items: flex-start;
  position: relative;

  @include breakpoint(xs) {
    flex-direction: column;
    padding: 32px 0 32px;
  }
}

.footer__social {
  width: 220px;
  margin-right: 240px;

  @include breakpoint(sm) {
    margin-right: 128px;
  }
}

.footer__social-title {
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 12px;
}

.footer__social-list {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 40px;
}

.footer__social-link {
  width: 40px;
  height: 40px;
  margin-right: 12px;
  border-radius: 20px;
  color: #fff;
  transition: background-color 0.1s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center center;
}
.footer__social-link--instagram {
  background-image: url("images/footer/inst_icon.svg");
}

.footer__links {
  flex: 1 1;
  display: flex;
  flex-flow: row;

  @include breakpoint(xs) {
    flex-direction: column;
  }
}

.footer__links-item {
  margin-bottom: 4px;
  transition: color 0.3s ease;

  &:hover {
    color: $rose-300;
  }

  &:last-child {
    margin: 0;
  }
}

.footer__links-section {
  display: flex;
  flex-direction: column;
  min-width: 160px;
  margin-bottom: 32px;
  margin-right: 48px;

  &:last-child {
    margin: 0;
  }
}

.footer__copyright {
  text-align: center;
  color: $white;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 16px;
}
