.topbar {
  position: relative;
  width: 100%;
  height: 96px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $adieu;
  background-color: $blue-500;

  @include breakpoint(sm) {
    height: 64px;
    padding: 0 20px;
  }

  &--burgers {
    background-color: $rose-300;
  }
}

.topbar__logo {
  display: flex;
  align-items: center;
}

.topbar__logo-icon {
  width: 137px;
  height: 64px;
  margin-right: 16px;
  background-image: url("images/king_kong_logo.png");
  background-size: contain;
  background-repeat: no-repeat;

  @include breakpoint(sm) {
    width: 100px;
    height: 47px;
  }

  &--rose {
    background-image: url("images/king_kong_logo_rose.png");
  }
}

.topbar__menu {
  display: flex;
  align-items: center;

  @include breakpoint(xs) {
    display: none;
  }
}

.topbar__menu-item {
  font-size: 16px;
  margin-right: 32px;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.2s ease;
  color: $white;

  &:last-child {
    margin: 0;
  }

  &:hover {
    color: $blue-300;
  }

  @include breakpoint(xs) {
    margin-right: 0;
    margin-bottom: 8px;
  }
}

.topbar__hamburger {
  display: none;
  position: relative;
  width: 24px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include breakpoint(xs) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &:before {
    content: "";
    position: absolute;
    top: -20px;
    right: -16px;
    bottom: -20px;
    left: -16px;
  }
}

.topbar__hamburger-line {
  width: 100%;
  height: 2px;
  background-color: $rose-300;
  transition: opacity 0.2s, transform 0.2s;
}

.is-menu-visible .topbar__hamburger-line--1 {
  transform: translateY(9px) rotate(45deg);
}

.is-menu-visible .topbar__hamburger-line--2 {
  opacity: 0;
}

.is-menu-visible .topbar__hamburger-line--3 {
  transform: translateY(-9px) rotate(-45deg);
}

.topbar__menu-mobile {
  display: none;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  background-color: $white;
  transform: translateZ(0);
  z-index: 999;
  top: 64px;
  width: 100%;
  bottom: 0;
  left: -100vw;
  opacity: 0;
  transition: opacity 0.3s;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  pointer-events: none;

  &.is-visible {
    pointer-events: auto;
    left: 0;
    opacity: 1;
  }

  & .topbar__menu-item {
    color: $black-300;
  }

  @include breakpoint(xs) {
    display: flex;
  }
}
