.header {
  position: relative;
  width: 100%;
  height: 664px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $blue-500;

  @include breakpoint(sm) {
    height: 488px;
  }

  @include breakpoint(xs) {
    display: none;
  }

  &--burgers {
    background-color: $rose-300;
  }
}

.header__container {
  max-width: 1400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__desktop-switch {
  position: absolute;
  width: 100%;
  height: 42px;
  bottom: 32px;

  @include breakpoint(sm) {
    display: none;
  }
}

.header__content {
  max-width: 702px;

  @include breakpoint(md) {
    max-width: 558px;
  }

  @include breakpoint(sm) {
    max-width: 450px;
  }
}

.header__title {
  font-family: $styrene;
  text-transform: uppercase;
  font-size: 38px;
  line-height: 64px;
  font-weight: 700;
  color: $white;
  margin-right: 16px;
  margin-bottom: 64px;

  @include breakpoint(md) {
    font-size: 30px;
    line-height: 52px;
    margin-bottom: 32px;
  }

  @include breakpoint(sm) {
    font-size: 24px;
    line-height: 44px;
    margin-bottom: 32px;
  }
}

.header__subtitle {
  font-family: $styrene;
  text-transform: uppercase;
  font-size: 28px;
  color: $rose-300;

  &--white {
    color: $white;
  }

  @include breakpoint(sm) {
    font-size: 20px;
  }

  @include breakpoint(xs) {
    font-size: 16px;
  }
}

.header__image {
  width: 643px;
  height: 643px;
  background-image: url("images/header_image.png");
  background-size: contain;
  background-repeat: no-repeat;

  @include breakpoint(md) {
    width: 420px;
    height: 420px;
  }

  @include breakpoint(sm) {
    width: 400px;
    height: 400px;
  }

  &--burger {
    width: 520px;
    height: 432px;
    background-image: url("images/header_image_burger.png");

    @include breakpoint(md) {
      width: 424px;
      height: 354px;
    }

    @include breakpoint(sm) {
      height: 324px;
    }
  }
}

.header__promo-title {
  font-family: $opensans;
  font-size: 24px;
  color: $red-300;
  margin-bottom: 16px;
  font-weight: 600;
}

.header__promo-code {
  font-family: $opensans;
  display: inline-block;
  padding: 12px 32px;
  background-color: $red-300;
  border-radius: 6px;
  color: $white;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}
