body {
  font-size: 16px;
  line-height: 28px;
  position: relative;
  color: $black;
  font-weight: 500;
  background-color: $white;
  min-height: 100vh;

  /*
   * Setting `font-display` property inside `@font-face` declaration as
   * well as preloading font with link preload helps to avoid FOIT in
   * the modern browsers (>70% of resume.io clients).
   */
  font-family: $montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.is-modal-open {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
p {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
}

input,
textarea {
  font-family: inherit;
  outline: none;
}

button {
  font-family: inherit;
}
